.different {
  margin: 0;
  padding: 80px 0 40px;

  img.graphicImage {
    max-width: 400px;
    margin-bottom: 30px;
    width: 100%;
  }

  .section-header {
    h1 {
      font-size: 42px;
    }
  }

  p.callOut {
    margin: 20px auto;
    text-align: center;
    font-weight: 500;
  }

  #values {
    height: 120px;
    margin-top: -120px;
  }

  .callOuts {
    list-style: none;
    margin-left: 0;
    padding-left: 0;

    li {
      display: flex;
      align-items: flex-start;
      font-size: 18px;

      svg {
        color: #203878;
        flex-shrink: 0;
        margin: 5px 10px 0 0;
      }
    }
  }

  .feature-split-wrap {
    margin: -30px auto;

    .feature-split {
      display: flex;
      align-items: flex-start;
      margin: 120px auto;

      .downIcon {
        margin: -5px auto 21px;
        text-align: center;
        display: flex;
        justify-content: center;
      }

      &.values {
        margin-top: 0;
      }

      > div {
        width: 48%;
        margin-right: 4%;

        &:last-child {
          margin-right: 0;
        }
      }
      h2 {
        font-weight: 300;
        font-size: 32px;
        line-height: 1.2em;
        margin-bottom: 25px;
      }

      strong {
        color: #101D2D;
      }

      .ant-carousel {
        margin-bottom: 20px;

        &:only-child {
          margin-top: 40px;
        }

        .slick-list {
          box-shadow: 0 7px 33px 10px rgba(16,29,45,0.21);
          padding: 20px;
          background: linear-gradient(180deg, #ffffff, #f2f2f2);
          border-radius: 10px;
        }

        .slick-slide {
          padding: 10px 40px 10px 20px;

          p {
            margin: 0;
            font-size: 18px;
            cursor: pointer;

            &.clipped {
              display: -webkit-box;
              -webkit-line-clamp: 5;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }

          .user {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 20px auto 0;

            img {
              width: 120px;
              height: 120px;
              border-radius: 100%;
              margin-right: 20px;
              object-fit: cover;
              flex-shrink: 0;
            }

            .details {
              h4 {
                margin: 0;
                font-size: 24px;
                font-weight: 600;
              }
              h5 {
                font-weight: 300;
                margin: 0;
                font-size: 22px;
                color: #666;
              }
            }
          }
        }
        .slick-dots.slick-dots-bottom {
          bottom: -20px;
          margin: 0;

          button {
            background: transparent;
          }
          button:before {
            opacity: 1;
          }
          li.slick-active {
            width: 16px;
            button {
              background: transparent;
            }

            button:before {
              color: #203878;
            }
          }
        }
      }
    }
  }

  .bgImg {
    width: 100%;
    height: 100%;
    max-height: 440px;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      transition: all 1.5s ease-in-out;
      object-fit: cover;
    }
  }
}

.mission {
  padding: 100px 10px;
  background: #203878;
  background: radial-gradient(circle, rgba(32, 56, 120, 0.675508) 0%, #203878 71%);

  .section-header {
    padding-bottom: 10px;
    h2 { font-size: 32px; }
  }

  h2 {
    font-weight: 300;
    font-size: 33px;
  }
}

@media (max-width: 767px) {
  .different {

    .bgImg {
      max-height: 530px;
    }
    .callOuts {
      li {
        align-items: flex-start;
        svg {
          margin-top: 5px;
        }
      }
    }
    .feature-split-wrap {
      .feature-split {
        flex-direction: column;
        > div {
          width: 100%;
          margin-right: 0;
          p {
            text-align: left;
          }
          margin-top: 20px;
        }

        &:nth-child(2) {
          flex-direction: column-reverse;
        }
        &:nth-child(4) {
          flex-direction: column-reverse;
        }
      }
    }
  }
}
