.talesPage {
  margin: 0;
  padding: 20px 10px;

  .section-header {
    h1 {
      font-size: 42px;
    }
  }

  .bgImg {
    width: 100%;
    height: 100%;
    max-height: 440px;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: #203878dd;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      transition: all 1.5s ease-in-out;
      object-fit: cover;
      object-position: 0 -50px;
      z-index: -1;
      filter: brightness(0.7);
    }
  }

  .photoGallery {
    margin: 50px auto;
    text-align: center;
  }

  .react-photo-gallery--gallery {
    > div {
      display: grid !important;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      grid-gap: 1rem;

      img {
        height: 250px;
        width: 250px;
        margin: auto !important;
        object-fit: contain;
      }
    }
  }
}


.react-images__view {
  img {
    max-height: 100vh;
    max-width: 100vw;
  }
}

@media (max-width: 767px) {
  .talesPage {
    .bgImg {
      max-height: 600px;

      img {
        object-position: center center;
      }
    }

    .react-photo-gallery--gallery {
      > div {
        img {
          width: 100vw;
        }
      }
    }
  }
}
