.container.tales {
  padding: 50px 0;

  div {
    &:focus {
      outline: none;
    }
  }

  .section-header {
    max-width: 700px;
    margin: 0 auto;
  }

  img {
    cursor: pointer;
    padding: 10px;
    height: 250px;
    object-fit: contain;
    object-position: top center;
    width: 380px;
    transition: 0.21s ease-in-out all;

    &:hover {
      filter: brightness(1.2);
      transform: scale(1.1)
    }
  }

  .slick-dots {
    bottom: auto;
  }

  a.bottom {
    display: block;
    text-decoration: none;
    margin: 70px auto 50px;

    .button {
      transition: 0.21s ease-in-out all;
    }
  }
}
