.companies {
  margin: 0;
  padding: 20px 10px;

  .section-header {
    h1 {
      font-size: 42px;
    }
  }

  .bgImg {
    width: 100%;
    height: 100%;
    max-height: 440px;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      transition: all 1.5s ease-in-out;
      object-fit: cover;
    }
  }

  .tiles-wrap {
    margin: 30px auto;
    a {
      text-decoration: none;
      flex-basis: 33%;
      @media (max-width: 600px) {
        flex-basis: 50%;
      }
      @media (max-width: 400px) {
        flex-basis: 100%;
      }
    }
  }

  .companyContainer {
    cursor: pointer;

    &:hover {
      h4 {
        opacity: 1;
        transform: scale(1.1) translateY(10px);
      }
      img {
        opacity: 1;
        transform: scale(1.1);
      }
    }

    h4 {
      transition: 0.21s ease-in-out all;
      color: #203878;
      font-size: 15px;
      margin: 0;
      line-height: 1.2em;
      opacity: 0;
      text-align: center;
    }

    img {
      opacity: 0.9;
      padding: 10px;
      height: 80px;
      object-fit: contain;
      width: 100%;
      display: block;
      transition: 0.21s ease-in-out all;
      &.top {
        margin-top: -12px;
      }
    }
  }

  .footnote {
    font-size: 14px;
    max-width: 700px;
    margin: 20px auto;
  }
}

.companyModal {
  text-align: center;

  .button {
    margin: 20px auto 10px 50%;
    text-align: center;
    transform: translateX(-50%);
  }

  h2,
  p {
    text-align: center;
  }
  p.exit {
    font-style: italic;
  }
  h2 {
    font-size: 28px;
    margin: 35px auto 20px;
    color: #203878;
    line-height: 1.38em;
  }
  img {
    max-height: 90px;
    max-width: 221px;
    text-align: center;
    margin: 0 auto;
  }
}

@media (max-width: 767px) {
  .companies {
    .bgImg {
      max-height: 530px;
    }
  }
}
