.clients.invert-color {
  background: #203878;
  background: radial-gradient(circle, rgba(32,56,120,0.6755077030812324) 0%, rgba(32,56,120,1) 71%);
}

.container.clients {
  padding: 0 ;

  div {
    &:focus {
      outline: none;
    }
  }

  .section-header {
    margin: 0 auto;
    h2 {
      font-weight: 300;
      font-size: 36px;
      line-height: 46px;
    }
    p {
      strong {
        color: #101D2D;
        font-style: italic;
      }
    }
  }

  .companiesList {
    display: flex;
    flex-wrap: wrap;

    // https://css-tricks.com/color-filters-can-turn-your-gray-skies-blue/
    .defs-only {
      position: absolute;
      height: 0; width: 0;
      overflow: none;
      left: -100%;
    }

    // https://stackoverflow.com/questions/29546550/flexbox-4-items-per-row
    .companyContainer {
      min-height: 150px;
      margin: 5px;
      width: calc(100% * (1/6) - 10px - 1px);
      flex-grow: 1;
      cursor: pointer;

      &:hover {
        h4 {
          opacity: 1;
          transform: scale(1.1) translateY(10px);
        }
        img {
          opacity: 1;
          transform: scale(1.1);
        }
      }

      h4 {
        transition: 0.21s ease-in-out all;
        color: #203878;
        font-size: 15px;
        margin: 0;
        line-height: 1.2em;
        opacity: 0;
      }

      img {
        opacity: 0.9;
        padding: 10px;
        height: 90px;
        object-fit: contain;
        width: 100%;
        display: block;
        transition: 0.21s ease-in-out all;
      }
      a, a:hover, a:active {
        text-decoration: none;
      }
    }
  }
  .buttons {
    margin: 10px auto 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    a:first-child {
      margin-right: 20px;
    }
  }
  a.bottom {
    display: block;
    text-decoration: none;

    .button {
      transition: 0.21s ease-in-out all;
    }
  }
}

@media (max-width: 767px) {
  .container.clients {
    .buttons {
      justify-content: center;
    }
    .companiesList .companyContainer {
      width: calc(100% * (1/3) - 10px - 1px);
    }
  }
}
