.testimonial {
  text-align: center;
  svg {
    display: block;
    margin: 0 auto;
  }

  &.has-bg-color-cut {
    &::before {
      height: calc(100% - 50px) !important;
      background-color: #203878bb !important;
    }
    &::after {
      content: '';
      width: 100%;
      height: calc(100% - 50px);
      position: absolute;
      left: 0;
      top: 0;
      z-index: -11;
      background: #203878;
      // -webkit-clip-path: polygon(0 0, 5760px 0, 5760px calc(100% - 352px), 0 100%);
      // clip-path: polygon(0 0, 5760px 0, 5760px calc(100% - 352px), 0 100%);
    }
  }

  h1 {
    font-size: 44px;
    line-height: 1.2em;
  }

  h4 {
    font-size: 22px;
  }

  .videoWrapper {
    width: 100%;
    height: calc(100% - 50px);
    position: absolute;
    top: 0;

    video {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      transition: all 1.5s ease-in-out;
      object-fit: cover;
      z-index: -10;
      // -webkit-clip-path: polygon(0 0, 5760px 0, 5760px calc(100% - 352px), 0 100%);
      // clip-path: polygon(0 0, 5760px 0, 5760px calc(100% - 352px), 0 100%);
    }
  }

  .tiles-wrap {
    a {
      display: flex;
      text-decoration: none;
      margin: 12px;
      cursor: pointer;
      transition: 0.21s ease all;
      flex-basis: 244px;
      max-width: 244px;

      &:hover {
        transform: translateY(-7px);
        filter: brightness(1.2);

      }

      .tiles-item {
        padding: 0;

        .tiles-item-inner {
          border-radius: 7px;
          transition: 0.21s ease all;
          flex-direction: column;
          padding: 25px;
          background: linear-gradient(180deg, #E2EEFF, #7f94aa);

          h4 {
            color: #010101;
            text-align: left;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.5em;
            font-family: "Inter", sans-serif;
            strong {
              font-weight: 900;
              color: #203878;
            }
          }

          .readMore {
            font-size: 11px;
            text-transform: uppercase;
            color: #010101;
            letter-spacing: 2 px;
            line-height: 1em;
            display: flex;
            align-items: center;
            text-align: left;
            margin: 20px 0 0;
            font-weight: 500;

            svg {
              color: #101D2D;
              font-size: 16px;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
