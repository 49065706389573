.site-header {

  .site-header-inner {
    height: 100px;
  }

  .logo {
    height: 66px;
  }

  &.fixed {
    .site-header-inner {
      height: 80px;
    }

    .logo {
      height: 50px;
    }
  }
}

.logo {
  height: 50px;
  filter: brightness(1.7);
}

.section-header {
  h2 {
    font-weight: 300;
    font-size: 36px;
    line-height: 46px;
  }
}

@media (max-width: 767px) {
  .site-header {
    .logo {
      height: 50px;
    }
  }
}
