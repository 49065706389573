.numbers {
  padding: 50px 0;
  .section-header {
    padding-bottom: 30px;
  }

  &.invert-color {
    .numbersList {
      .listItem {
        p {
          &.number {
            color: #fff;
          }
        }
      }
    }
  }

  &:not(.invert-color) {
    background: #fff;
    h2 {
      color: #101D2D;
    }
    p {
      color: #3d4a58;
    }

    .numbersList {
      align-items: baseline;
      .listItem {
        p {
          &:first-child {
            max-height: unset;
          }

          &.number {
            margin: 0;
            color: #203878;
          }
        }
      }
    }
  }

  .numbersList {
    min-height: 115px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .listItem {
      width: 100%;
      text-align: center;
      p {
        margin: 0;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;

        &:first-child {
          line-height: 1.3em;
          min-height: 35px;
          align-items: center;
          display: flex;
          justify-content: center;
        }

        &.number {
          font-size: 44px;
          font-weight: 800;
          margin: 15px auto 20px;
        }

        &:last-child {
          font-size: 14px;
          line-height: 1.4em;
          font-weight: 600;
        }
      }

      h2 {
        margin: 0;
      }
    }
  }
}

@media (max-width: 800px) {
  .numbers {
    .invert-color .numbersList {
      min-height: 820px;
    }
    .numbersList {
      display: block;
      min-height: 640px;

      .listItem {
        padding: 20px 0;

        p.number {
          margin: 0 auto;
        }
      }
    }
  }
}
