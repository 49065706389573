svg.enable-background {
  position: absolute;
  width: 100%;
  height: 100%;
}

.testimonial {
  svg.enable-background {
    height: calc(100% - 50px);
    margin-top: -80px;
  }
}

.numbers {
  svg.enable-background {
    margin-top: -50px;
  }
}
#home {
  clip-path: url(#cache);

  use {
    overflow: visible;
  }
}

#red {
  fill: none;
  opacity: 0;
  stroke: #eee00;
  stroke-width: 0;
  stroke-miterlimit:10;
  animation: show 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

#blue {
  fill: none;
  opacity: 0.15;
  stroke: #fff;
  stroke-width: 6;
  stroke-miterlimit:10;
  animation: show 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

#light-blue {
  fill: none;
  opacity: 0.15;
  stroke: #2174EA;
  stroke-width: 6;
  stroke-miterlimit:10;
  stroke-dasharray: 200;
  stroke-dashoffset: 800;
  animation: draw 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes show {
  0% {
    opacity: 0.15;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.15;
  }
}

@media (max-width: 800px) {
  #light-blue, #blue {
    stroke-width: 2;
  }
}
