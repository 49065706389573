.companyTeam {
  margin: 0;
  padding: 20px 10px;

  .section-header {
    h1 {
      font-size: 42px;
    }
  }

  .tab-list {
    justify-content: center;
    margin-bottom: 25px !important;
  }

  .bgImg {
    width: 100%;
    height: 100%;
    max-height: 500px;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: #203878cc;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      transition: all 1.5s ease-in-out;
      object-fit: cover;
      z-index: -1;
      filter: brightness(0.7);
    }
  }

  .tiles-wrap a {
    text-decoration: none;
  }

  .teamContainer {
    cursor: pointer;
    margin-bottom: 50px;

    &:hover {
      img {
        filter: brightness(0.7);
      }

      h2, h3 { opacity: 0.7; }
    }

    .tiles-item-inner {
      padding: 0;
      border-radius: 100%;
    }

    h2 {
      font-size: 21px;
      font-weight: 300;
      margin: 0;
      text-align: center;
    }
    h3 {
      transition: 0.21s ease-in-out all;
      color: #203878;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
      margin: 0;
      line-height: 1.2em;
      text-align: center;
    }

    img {
      width: 275px;
      height: 275px;
      object-fit: cover;
      display: block;
      transition: 0.21s ease-in-out all;
      border-radius: 100%;
      object-position: 0 -25px;

      &.original {
        object-position: 0 0;
      }
    }
  }
}

.teamModal {
  width: 100% !important;
  max-width: 800px;

  .ant-modal-body {
    display: flex;
  }
  .info {
    width: 50%;
    h2 {
      font-size: 28px;
      margin: 10px 0 0;
    }
    h3 {
      transition: 0.21s ease-in-out all;
      color: #203878;
      text-transform: uppercase;
      font-weight: 500;
      margin: 0 0 35px 0;
      font-size: 18px;
      line-height: 1.2em;
      display: flex;
      align-items: center;
      svg {
        margin-left: 10px;
        color: #2867B2;
      }
    }
  }
  img {
    width: 45%;
    height: 500px;
    object-fit: cover;
    margin: 35px 0 0 25px;
  }
}

@media (max-width: 767px) {
  .teamModal {

    .ant-modal-body {
      flex-direction: column;
      flex-flow: column-reverse;
    }
    .info {
      width: auto;
      h3 { margin-bottom: 20px;}
    }


    img {
      width: auto;
      margin: 25px 0 0;
    }
  }
}
